import React from 'react'
import { Layout, SEO } from "@startuponsale/gatsby-shared-ui";
import Articles  from '../components/Articles';
import { graphql } from 'gatsby'
import website from "../../config/website";

const CategoryTemplate = ({ pageContext, data, location }) => {
  const { category } = pageContext
  const { nodes:articles  } = data.allMdx

  const nuevasKeywords = articles.map((post) => {
                            const { keywords } = post.frontmatter;
                            return keywords;});

console.log(location.pathname)
  return (
    <Layout blog={true}>
      <SEO
        title={`${category} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.description}
        uniqueKeywords={nuevasKeywords + ", " + category}
      />
      <Articles articles={articles} title={"Articulos " + category} />
    </Layout>
  )
}

export const query = graphql`
  query GetCategories($category: String) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $category }, published: { eq: true } } }
    ) {
      nodes {
        frontmatter {
            title
            author
            category
            readTime
            slug
            date(formatString: "MMMM, Do YYYY")
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            keywords
          }
          id
          excerpt
      }
    }
  }
`

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export default CategoryTemplate